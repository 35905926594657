import _debounce from 'lodash/debounce'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserNamespace } from './serviceHelpers'

export function getListByCategory(category, offset, limit, query, status, documentCategory) {
  const params = { category, offset, limit }

  if (query) {
    params.query = query
  }

  if (status) {
    params.status = status
  }

  if (Array.isArray(documentCategory)) {
    const items = []

    documentCategory.map(individualCategory => {
      items.push(individualCategory.id)
    })

    params.documentCategory = items.toString()
  } else if (documentCategory) {
    params.documentCategory = documentCategory
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/document`, { params })
    .then(({ data }) => data)
}

export function getNotViewedDocumentsCounts(category, offset, limit, query, status, documentCategory) {
  const params = {}

  if (documentCategory) {
    params.documentCategory = documentCategory
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/documents/not-viewed`, { params })
    .then(({ data }) => data)
}

export function getInfo(uuid) {
  return apiClient
    .get(`v1/${getUserNamespace()}/document/${uuid}/info`)
    .then(({ data }) => data)
}

export function getTemporaryInfo(temporarySignLinkUuId, sessionId) {
  return apiClient
    .get(`v1/sign/document/${temporarySignLinkUuId}/${sessionId}/info`)
    .then(({ data }) => data)
}

export function getTemporaryInfoV2(temporarySignLinkUuId, sessionId, authSessionId) {
  return apiClient
    .get(`sign/bulk/document/${temporarySignLinkUuId}/${sessionId}/info`, { headers: { 'X-AUTH-SESSION-ID': authSessionId } })
    .then(({ data }) => data)
}

export function getFiles(uuid) {
  return apiClient
    .get(`v1/${getUserNamespace()}/document/${uuid}/files`)
    .then(({ data }) => data)
}

export function getFilesForTemporaryPreview(uuid, temporarySignLinkId, headers) {
  return apiClient
    .get(`v1/${getUserNamespace()}/document/${uuid}/files/${temporarySignLinkId}`, { headers })
    .then(({ data }) => data)
}

export function remove(uuid) {
  return apiClient
    .delete(`v1/${getUserNamespace()}/document/${uuid}`)
    .then(({ data }) => data)
}

export function initializeSigning(uuid, params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${uuid}/sign`, params)
    .then(({ data }) => data)
}

export function initializeTemporarySigning(uuid, temporarySignLinkId, sessionId, params) {
  return apiClient
    .post(`v1/sign/document/${uuid}/${temporarySignLinkId}/${sessionId}/sign`, params, { headers: { 'X-AUTH-SESSION-ID': params.authSessionId } })
    .then(({ data }) => data)
}

export function getTemporarySigningInfo(uuid, sessionId, authSessionId) {
  return apiClient
    .get(`v1/sign/${uuid}/${sessionId}/info`, { headers: { 'X-AUTH-SESSION-ID': authSessionId } })
    .then(({ data }) => data)
}

/**
 * @param {string} uuid
 * @param {object} params
 * @param {int} timeLimit
 * @param {string=} iframeLinkId
 * @returns {{cancel: (function(): boolean), promise: Promise<Object>}}
 */
export function pollSignStatus(uuid, params, timeLimit, iframeLinkId) {
  let canceled = false
  const timeLimitMs = timeLimit * 1000
  const startTime = new Date()
  const url = iframeLinkId ? `v1/sign/document/${uuid}/sign-status/${iframeLinkId}` : `v1/${getUserNamespace()}/document/${uuid}/sign-status`
  const promise = new Promise(
    (resolve, reject) => {
      const requestStatus = () => {
        if (new Date() - startTime > timeLimitMs) {
          return reject(new Error('Timeout while signing a document'))
        }

        apiClient
          .get(url, { params, ...{ headers: { 'X-AUTH-SESSION-ID': params.authSessionId } } })
          .then(({ data }) => {
            if (canceled) { return reject() }
            if (data.status === 'ok') { return resolve(data) }

            requestStatusDebounced()
          })
          .catch(reject)
      }
      const requestStatusDebounced = _debounce(requestStatus, 1000)

      requestStatusDebounced()
    }
  )

  return {
    promise,
    cancel: () => canceled = true,
  }
}

/**
 * @param {string} uuid
 * @param {object} params
 * @param {int} timeLimit
 * @param {string=} iframeLinkId
 * @returns {{cancel: (function(): boolean), promise: Promise<Object>}}
 */
export function pollControlCode(uuid, params, timeLimit, iframeLinkId) {
  let canceled = false
  const timeLimitMs = timeLimit * 1000
  const startTime = new Date()
  const url = iframeLinkId ? `v1/sign/document/${uuid}/sign-status/${iframeLinkId}` : `v1/${getUserNamespace()}/document/${uuid}/sign-status`
  const promise = new Promise(
    (resolve, reject) => {
      const requestStatus = () => {
        if (new Date() - startTime > timeLimitMs) {
          return reject(new Error('Timeout while requesting control code'))
        }

        const { authSessionId } = params
        apiClient
          .get(url, { params, ...{ headers: { 'X-AUTH-SESSION-ID': authSessionId } } })
          .then(({ data }) => {
            if (canceled) { return reject() }
            if (data.control_code) { return resolve(data) }

            requestStatusDebounced()
          })
          .catch(reject)
      }
      const requestStatusDebounced = _debounce(requestStatus, 1000)

      requestStatusDebounced()
    }
  )

  return {
    promise,
    cancel: () => canceled = true,
  }
}

export function inviteSigner(uuid, params) {
  return apiClient
    .post(`/v1/${getUserNamespace()}/document/${uuid}/invite-signer`, params)
    .then(({ data }) => data)
}

export function inviteSigners(uuid, params) {
  return apiClient
    .post(`/v1/${getUserNamespace()}/document/${uuid}/invite-signers`, params)
    .then(({ data }) => data)
}

export function save(uuid, params) {
  return apiClient
    .post(`/${getUserNamespace()}/document/${uuid}/save`, params)
    .then(({ data }) => data)
}

export function updateDocumentTitle(uuid, params) {
  return apiClient
    .put(`/v1/${getUserNamespace()}/document/${uuid}/display-title/update`, params)
    .then(({ data }) => data)
}

export function saveDraft(uuid, params) {
  return apiClient
    .post(`/${getUserNamespace()}/document/${uuid}/draft/save`, params)
    .then(({ data }) => data)
}

export function removeSelected(documentsIds) {
  const params = { documentsIds }

  return apiClient
    .delete(`/v1/${getUserNamespace()}/documents`, { params })
    .then(({ data }) => data)
}

export function getHistoryRecords(uuid) {
  return apiClient
    .get(`v1/${getUserNamespace()}/document/history/${uuid}`)
    .then(({ data }) => data)
}

export function initializeDocumentValidation(uuid, validationPurpose) {
  const controller = new AbortController()
  const cancelTokenSource = apiClient.defaults.cancelTokenSource()

  return {
    promise: apiClient
      .post(`v1/${getUserNamespace()}/document/${uuid}/initialize-validation?purpose=${validationPurpose}`, {
        cancelToken: cancelTokenSource.token,
        signal: controller.signal
      })
      .then(({ data }) => typeof data.data !== 'undefined' ? data.data : new Error('Undefined data field')),
    cancel: (reason) => {
      typeof cancelTokenSource === 'object' && typeof cancelTokenSource.cancel === 'function' ? cancelTokenSource.cancel(reason) : (typeof controller === 'object' && typeof controller.abort === 'function' ? controller.abort(reason) : null)
    }
  }
}

/**
 * @param {string} sessionId
 * @param {string} documentId
 * @param {int} timeLimit
 * @returns {{cancel: (function(): boolean), promise: Promise<Object>}}
 */
export function pollValidationStatus(sessionId, documentId, timeLimit) {
  let canceled = false
  const timeLimitMs = timeLimit * 1000
  const startTime = new Date()
  const url = `v1/${getUserNamespace()}/document/${documentId}/validation/${sessionId}`
  const promise = new Promise(
    (resolve, reject) => {
      const requestStatus = () => {
        if (new Date() - startTime > timeLimitMs) {
          return reject(new Error('Timeout while trying to find validation information.'))
        }

        apiClient
          .get(url)
          .then(({ data }) => {
            if (canceled) { return reject() }
            if (data.status === 'ok') { return resolve(data) }

            requestStatusDebounced()
          })
          .catch(reject)
      }
      const requestStatusDebounced = _debounce(requestStatus, 1000)

      requestStatusDebounced()
    }
  )

  return {
    promise,
    cancel: () => canceled = true,
  }
}

/**
 * @param {string} sessionId
 * @param {string} documentId
 * @param {int} timeLimit
 * @returns {{cancel: (function(): boolean), promise: Promise<Object>}}
 */
export function pollMemberStatus(sessionId, documentId, timeLimit) {
  let canceled = false
  const timeLimitMs = timeLimit * 1000
  const startTime = new Date()
  const url = `v1/${getUserNamespace()}/document/${documentId}/validation/${sessionId}/member-status`
  const promise = new Promise(
    (resolve, reject) => {
      const requestStatus = () => {
        if (new Date() - startTime > timeLimitMs) {
          return reject(new Error('Timeout while trying to find validation information.'))
        }

        apiClient
          .get(url)
          .then(({ data }) => {
            if (canceled) { return reject() }
            if (data.status === 'ok') { return resolve(data) }

            requestStatusDebounced()
          })
          .catch(reject)
      }
      const requestStatusDebounced = _debounce(requestStatus, 1000)

      requestStatusDebounced()
    }
  )

  return {
    promise,
    cancel: () => canceled = true,
  }
}

export function searchDocumentCategories(query, assignedCategories, visibleDocumentCategories) {
  const params = {}

  if (assignedCategories) {
    params.assignedCategories = assignedCategories
  }

  if (visibleDocumentCategories) {
    params.visibleDocumentCategories = visibleDocumentCategories
  }

  if (query) {
    params.query = query
  }

  return apiClient
    .get(`v1/${getUserNamespace()}/documents/all-categories`, { params })
    .then(({ data }) => data)
}

export function getAllDocumentCategories() {
  return apiClient
    .get(`v1/${getUserNamespace()}/documents/all-categories`)
    .then(({ data }) => data)
}

export function resendSignerInvitation(documentUuid, signerId) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${documentUuid}/signer/${signerId}/resend-invitation`)
    .then(({ data }) => data)
}

export function getDocumentComments(uuid) {
  return apiClient
    .get(`v1/${getUserNamespace()}/document/comments/${uuid}`)
    .then(({ data }) => data)
}

export function addComment(uuid, params) {
  return apiClient
    .post(`/v1/${getUserNamespace()}/document/${uuid}/comment`, params)
    .then(({ data }) => data)
}

export function removeComment(id) {
  return apiClient
    .post(`/v1/${getUserNamespace()}/document/comment/${id}`)
    .then(({ data }) => data)
}

export function migrateDocument(documentUuid, id) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${documentUuid}/migrate/${id}`)
    .then(({ data }) => data)
}

export function migrateSelected(businessId, params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/documents/migrate/${businessId}`, params)
    .then(({ data }) => data)
}

export function removeMember(uuid, memberId) {
  return apiClient
    .delete(`/v1/${getUserNamespace()}/document/${uuid}/remove-member/${memberId}`)
    .then(({ data }) => data)
}

export function migrateDocumentToWorkspace(documentUuid, workspaceId, isBusiness) {
  return apiClient
    .post(`v1/${getUserNamespace()}/migrate/document/${documentUuid}/${isBusiness ? 'business' : 'personal'}/${workspaceId}`)
    .then(({ data }) => data)
}

export function migrateSelectedDocumentsToWorkspace(documents, workspaceId, isBusiness) {
  return apiClient
    .post(`v1/${getUserNamespace()}/migrate/documents/${isBusiness ? 'business' : 'personal'}/${workspaceId}`, { documentIds: documents })
    .then(({ data }) => data)
}

/**
 * @param {string} uuid
 * @returns {Promise<Object>}
 */
export function getDocumentBilling(uuid) {
  return apiClient
    .get(`v1/${getUserNamespace()}/document/${uuid}/billing`)
    .then(({ data }) => data)
}

/**
 * @returns {Promise<Object>}
 */
export function initializeZealIdSigning(temporarySignLink) {
  temporarySignLink = temporarySignLink ? `/${temporarySignLink}` : ''
  const url = `v1/${getUserNamespace()}/initialize-zeal-document-sign${temporarySignLink}`

  return apiClient
    .post(url)
    .then(({ data }) => data)
}

/**
 * @returns {Promise<Object>}
 */
export function initializeZealIdTemporarySigning() {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/initialize-zeal-document-sign`)
    .then(({ data }) => data)
}

/**
 * @param {string} code
 * @param {string} loginSessionId
 * @param {array} documentIds
 * @param {string} temporarySignLink
 * @returns {Promise<Object>}
 */
export function getQrCodeForHashes(code, loginSessionId, documentIds, temporarySignLink) {
  return apiClient
    .request({
      method: 'post',
      url: `v1/${getUserNamespace()}/zealid/get-qr-for-hashes`,
      data: { code, loginSessionId, temporarySignLink, documentIds }
    })
    .then(({ data }) => data)
}

/**
 * @param {Object} params
 * @returns {Promise<Object>}
 */
export function finalizeDocumentsSign(params) {
  const url = params.temporarySignLink ? `v1/${getUserNamespace()}/bulk/zealid/finalize-documents-sign/${params.temporarySignLink}` : `v1/${getUserNamespace()}/zealid/finalize-documents-sign`
  return apiClient
    .request({
      method: 'post',
      url: url,
      data: params,
    })
    .then(({ data }) => data)
}

export function saveDocumentMemberPurpose(uuid, params) {
  return apiClient
    .post(`/${getUserNamespace()}/document/${uuid}/save-member-purpose`, params)
    .then(({ data }) => data)
}

export function updateDocumentMemberPurpose(uuid, params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${uuid}/update-member-purpose`, params)
    .then(({ data }) => data)
}

/**
 * @param {string[]} uuids
 * @param {Object} params
 * @returns {Promise}
 */
export function saveDrafts(uuids, params) {
  return Promise.all(uuids.map(uuid => saveDraft(uuid, params)))
}

export function rejectBulkTemporaryDocumentSigning(temporarySignLinkUuId, params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/bulk/reject/${temporarySignLinkUuId}`, params, { headers: { 'X-AUTH-SESSION-ID': params.authSessionId } })
    .then(({ data }) => data)
}

export function confirmDocument(documentUuId) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${documentUuId}/confirm`)
    .then(({ data }) => data)
}

export function denyDocument(documentUuId, params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${documentUuId}/deny`, params)
    .then(({ data }) => data)
}

export function updateDocumentDeadline(uuid, params) {
  return apiClient
    .post(`/v1/${getUserNamespace()}/document/${uuid}/deadline/update`, params)
    .then(({ data }) => data)
}

export function uploadAdocDocumentContents(mainFile, attachments, appendices, params) {
  const formData = new FormData()
  formData.append('file[0]', mainFile)
  attachments.map((attachment, index) => {
    formData.append(`attachment[${index}]`, attachment)
  })
  appendices.map((appendix, index) => {
    formData.append(`appendix[${index}]`, appendix)
  })
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return apiClient
    .post(`/${getUserNamespace()}/documents/upload` + `?type=${params.uploadType}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data)
}

export function getDiagnosticReportUrl(uuid, format) {
  // make sure that no one is trying to tweak the request parameters
  format = format === 'pdf' ? format : 'xml'
  return `/v1/${getUserNamespace()}/document/${uuid}/download-validation-report?format=${format}`
}

export function getDiagnosticReport(uuid, format) {
  // make sure that no one is trying to tweak the request parameters
  format = format === 'pdf' ? format : 'xml'
  return apiClient
    .get(getDiagnosticReportUrl(uuid, format))
    .then(({ data }) => data)
}

export function usbInitialDataFetch() {
  return apiClient
    .get('http://localhost:5000/index')
    .then(({ data }) => data)
}

export function usbMultiSign(pin, dataToBeSigned) {
  return apiClient
    .post(
      'http://localhost:5000/sign',
      {
        pin,
        type: 'sign',
        datatobesigned: dataToBeSigned,
      }
    )
    .then(({ data }) => data)
}

/**
 * @param {object} certificate
 * @param {string} documentUuid
 * @returns {Promise<Object>}
 */
export function usbSignDocument(documentUuid, certificate) {
  return initializeSigning(documentUuid, { certificate: certificate.encoded })
}

/**
 * @param {string} password
 * @param {string} temporarySignLinkId
 * @param {string} sessionId
 * @param {object} certificate
 * @param {string} documentUuid
 * @param {string} signatureType
 * @returns {Promise<Object>}
 */
export function usbTemporarySignDocument(documentUuid, temporarySignLinkId, sessionId, password, certificate, signatureType) {
  return initializeTemporarySigning(documentUuid, temporarySignLinkId, sessionId, { certificate: certificate.encoded, authMethod: signatureType })
    .then(({ token, hash }) => {
      sessionId = token
      const dataTobeSigned = {}
      dataTobeSigned[documentUuid] = hash
      return usbMultiSign(password, dataTobeSigned)
    })
    .then(({ signatures, error }) => {
      if (typeof signatures === 'undefined' || !signatures || signatures.length === 0) {
        return Promise.reject(new Error(error ? error : 'Signature is empty'))
      }

      return apiClient.post(`/v1/smart-card-usb/complete/${sessionId}`, { signature: signatures[0].signedHash })
    })
    .then(() => pollSignStatus(documentUuid, { token: sessionId, authMethod: signatureType }, 120, temporarySignLinkId).promise)
}

/**
 * @param {string} password
 * @param {string} temporarySignLinkId
 * @param {string} sessionId
 * @param {object} certificate
 * @param {string} documentUuid
 * @param {string} signatureType
 * @returns {Promise<Object>}
 */
export function usbTemporarySignDocumentV2(documentUuid, temporarySignLinkId, sessionId, password, certificate, signatureType) {
  return initializeTemporarySigning(documentUuid, temporarySignLinkId, sessionId, { certificate: certificate.encoded, authMethod: signatureType })
}

export function getTemporarySigningLink(params) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/signing-link`, params)
    .then(({ data }) => data)
}

export function getTemporarySigningLinkV2(params) {
  return apiClient
    .post(`v2/${getUserNamespace()}/document/signing-link`, params)
    .then(({ data }) => data)
}

export function updateDocumentMemberPhone(uuid, params) {
  return apiClient
    .post(`/v1/${getUserNamespace()}/document/${uuid}/member-phone/update`, params)
    .then(({ data }) => data)
}

export function moveDocuments(uuids, folders) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/move-documents`, { documents: uuids, folders } )
    .then(({ data }) => data)
}

export function completeUsbSign(sessionId, signedHash) {
  return apiClient.post(`/v1/smart-card-usb/complete/${sessionId}`, { signature: signedHash })
}

export function reviewDocument(documentUuId) {
  return apiClient
    .post(`v1/${getUserNamespace()}/document/${documentUuId}/review`)
    .then(({ data }) => data)
}

export function downloadTemporaryDocumentsFiles(temporarySignLinkId, authSessionId) {
  const url = `/sign/bulk/${temporarySignLinkId}/download-all`

  const headers = {}
  if (authSessionId) {
    headers['X-AUTH-SESSION-ID'] = authSessionId
  }

  return downloadFile(url, headers)
}

export function downloadTemporaryDocumentFile(documentId, temporarySignLinkId, authSessionId) {
  const url = `/v1/sign/bulk/document/${documentId}/${temporarySignLinkId}/download`
  const headers = {}
  if (authSessionId) {
    headers['X-AUTH-SESSION-ID'] = authSessionId
  }
  return downloadFile(url, headers)
}

export function downloadFile(url, headers) {
  return apiClient
    .get(url, { headers, responseType: 'blob' })
    .then(response => {
      return new Promise((resolve, reject) => {
        try {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const objectUrl = window.URL.createObjectURL(blob)

          const contentDisposition = response.headers['content-disposition']
          let fileName = 'downloaded'
          if (contentDisposition && contentDisposition.includes('filename=')) {
            fileName = contentDisposition
              .split('filename=')[1]
              .split(';')[0]
              .replace(/['"]/g, '')
          }

          const link = document.createElement('a')
          link.href = objectUrl
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          document.body.removeChild(link)
          window.URL.revokeObjectURL(objectUrl)
        } catch (e) {
          reject(e)
        }
      })
    })
}
