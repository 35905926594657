import React from 'react'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import apiClient from '~/js/utils/apiClientDocs'
import { getUserWorkspace } from '~/js/routes/router'
import StatusMessages from '~/js/containers/StatusMessages'
import * as settingsService from '~/js/services/user/settings'
import { ROUTE_NAMES } from '../../../config'
import { generatePath } from '../../../router'
import { SETTINGS_BILLING } from '../../../../models/Settings'
import ModalRemoveCompanyUsers from '../../../../components/ModalRemoveCompanyUsers'
import PropTypes from 'prop-types'

export default class UsersQuantityChange extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      quantity: 1,
      subtotalNow: 0,
      vatPercent: 0,
      vat: 0,
      totalNow: 0,
      subtotal: 0,
      total: 0,
      modalRemoveCompanyUsers: {
        active: false
      },
    }

    this.onSubmit = this.onSubmit.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.calculateValues = this.calculateValues.bind(this)

    this.fetchData()
      .then(licences => {
        this.setState({ quantity: licences.length })
        this.calculateValues(licences.length)
      })
  }

  fetchData() {
    return settingsService
      .getAllBusinessLicenses()
      .then(({ data: licences }) => {
        this.setState({ licences })

        return licences
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  handleChange(quantity) {
    this.setState({
      quantity: quantity
    })
  }

  calculateValues(quantity) {
    const { country } = this.props.location.state
    const { licences } = this.state

    return apiClient
      .post(`v1/${getUserWorkspace()}/checkout/calculate-values`, {
        quantity: quantity,
        usersQuantityChange: true,
        licencesCount: licences.length,
        country,
      })
      .then(data => this.setState({
        subtotalNow: data.data.subtotalNow,
        vatNow: data.data.vatNow,
        totalNow: data.data.totalNow,
        subtotal: data.data.subtotal,
        vatPercent: data.data.vatPercent,
        vat: data.data.vat,
        total: data.data.total
      }))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onSubmit() {
    const { t, user, location, products } = this.props
    const { quantity, licences } = this.state

    if (quantity > location.state.companyUsersQuantity) {
      const product = products.find(itm => itm.id === user.subscriptionProduct)
      const quantityToBuy = quantity - location.state.companyUsersQuantity

      this.props.clearCart()
      this.props.addCartItem(product, quantityToBuy)
      this.props.history.push({
        pathname: generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT),
      })
    } else if (quantity <= location.state.companyUsersQuantity) {
      apiClient
        .post(`v1/${getUserWorkspace()}/license/cancel`, {
          numberOfLicensesToCancel: licences.length - quantity,
        })
        .then(() => {
          this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.plan_was_successfully_cancelled'))
        })
        .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
        .finally(() => this.props.history.push(generatePath(ROUTE_NAMES.USER_SETTINGS, { category: SETTINGS_BILLING })))
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { quantity } = this.state

    if (quantity !== prevState.quantity) {
      this.calculateValues(quantity)
    }
  }

  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <main>
          <div className="grid">
            <div className="wrapped-section">
              <div className="wrapped-section__headline">
                <h2 className="headline headline--information">{t('user.pricing.order_summary')}</h2>
              </div>
              <div className="info-details">
                <ul>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.plan_name')}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{t(`user.pricing.${this.props.user.subscription}`)}</p>
                    </div>
                  </li>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.number_of_users')}</label>
                    </div>
                    <div className="info-details__info">
                      <input
                        className="form-group__input"
                        value={this.state.quantity}
                        type="number"
                        name="quantity"
                        min="1"
                        style={{ width: '80px' }}
                        onChange={e => this.handleChange(e.target.value)}
                      />
                    </div>
                  </li>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.subtotal_now')}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{this.state.subtotalNow}</p>
                    </div>
                  </li>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.vat_now')} {this.state.vatPercent ? `(${this.state.vatPercent}%)` : ''}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{this.state.vatNow}</p>
                    </div>
                  </li>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.total_now')}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{this.state.totalNow}</p>
                    </div>
                  </li>
                  <hr />
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.subtotal_in_future')}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{this.state.subtotal}</p>
                    </div>
                  </li>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.vat_in_future')} {this.state.vatPercent ? `(${this.state.vatPercent}%)` : ''}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{this.state.vat}</p>
                    </div>
                  </li>
                  <li>
                    <div className="info-details__label">
                      <label>{t('user.pricing.total_in_future')}</label>
                    </div>
                    <div className="info-details__info">
                      <p>{this.state.total}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div id="bottom-bar" className="bottom-bar">
              <button
                className="btn btn--primary"
                onClick={() => this.onSubmit()}
              >
                {t('submit')}
              </button>
            </div>
          </div>
          <StatusMessages />
        </main>
        <ModalRemoveCompanyUsers
          active={this.state.modalRemoveCompanyUsers.active}
          onCloseClick={() => this.setState({ modalRemoveCompanyUsers: { active: false } })}
          numberOfUsersToBeRemoved={this.props.location.state.companyUsersQuantity - this.state.quantity + 1}
          t={t}
        />
      </React.Fragment>
    )
  }
}

UsersQuantityChange.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  clearCart: PropTypes.func,
  addCartItem: PropTypes.func,
  user: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  products: PropTypes.array,
}
