import React from 'react'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as userService from '~/js/services/user/user'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import PropTypes from 'prop-types'
import ProIcon from '../ProIcon/ProIcon'
import Select from '../Select/Select'
import { signaturePositions } from '~/js/utils/signaturePositions'

export default class AdditionalSettings extends React.Component {
  constructor(props) {
    super(props)
    this.onAdditionalSettingsSubmit = this.onAdditionalSettingsSubmit.bind(this)
  }

  onAdditionalSettingsSubmit(values) {
    const { t } = this.props

    userService.updateAdditionalSettings(values, this.props.user)
      .then(() => {
        this.props.fetchUser()
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.settings_were_successfully_changed'))
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  render() {
    const { t, user } = this.props

    const signaturePositionOptions = signaturePositions.map(position => ({ labelKey: position.translationKey, value: position.id }))

    const initialValues = {
      checkBillDocumentOwner: user.checkBillDocumentOwner,
      signaturePosition: user.defaultSignaturePosition
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={this.onAdditionalSettingsSubmit}
      >
        {props => (
          <Form>
            <div className="wrapped-section additional-settings">
              <div className="wrapped-section__headline">
                <h2 className="headline headline--standard">{t('user.settings.document_upload_settings')}</h2>
              </div>
              <div className='info-details info-details--standard'>
                <ul>
                  <InfoDetailsItem
                    label={t('user.settings.additional_settings_tab.check_bill_document_owner')}
                    info={
                      <div className={'form-group form-group__checkbox' + (!user.permissions.canSetBillDocumentOwner ? ' disabled-checkbox' : '') + (props.errors.checkBillDocumentOwner && props.touched.checkBillDocumentOwner ? ' form-group--error' : '')}>
                        <Field type="checkbox" name="checkBillDocumentOwner" id="check-bill-documentowner" disabled={!user.permissions.canSetBillDocumentOwner} />
                        <label htmlFor='check-bill-documentowner'>&nbsp;</label>
                        <div className="pro-icon__mobile-adjustment">
                          {!user.permissions.canSetBillDocumentOwner && (
                            <ProIcon tooltipText={t('user.document.available_from_pro_tooltip')} inline={true} newLine={true} />
                          )}
                        </div>
                        <ErrorMessage className="form-group__error" name="checkBillDocumentOwner" component="span" />
                      </div>
                    }
                  />
                  <InfoDetailsItem
                    label={t('user.settings.additional_settings_tab.default_signature_placement')}
                    info={
                      <Field name="signaturePosition">
                        {({ field, form, meta }) => {
                          const defaultOption = signaturePositionOptions.find(opt => opt.value === field.value)

                          return (
                            <div className={'form-group form-group__select' + (meta.touched && meta.error ? ' form-group--error' : '')}>
                              <Select
                                name="signaturePosition"
                                options={signaturePositionOptions}
                                placeholder={t('common.select')}
                                defaultValue={defaultOption}
                                formatOptionLabel={signatureOption => t(signatureOption.labelKey)}
                                onChange={option => form.setFieldValue('signaturePosition', option.value)}
                                onBlur={field.onBlur}
                                className='form-group__select-wrapper'
                                classNamePrefix='form-group__select'
                              />
                              <ErrorMessage className="form-group__error" name="signaturePosition" component="span" />
                            </div>
                          )
                        }}
                      </Field>
                    }
                  />
                </ul>
              </div>
            </div>
            <div id="bottom-bar" className="bottom-bar">
              <button
                type="submit"
                className="btn btn--primary ta-center"
              >
                {t('common.save')}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    )
  }
}

AdditionalSettings.propTypes = {
  user: PropTypes.object,
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  fetchUser: PropTypes.func,
}

function InfoDetailsItem({ label, info }) {
  return (
    <li>
      <div className="info-details__label info-details__label--shorter">
        <label>{label}</label>
      </div>
      <div className="info-details__info">
        {info}
      </div>
    </li>
  )
}

InfoDetailsItem.propTypes = {
  label: PropTypes.string,
  info: PropTypes.any,
}