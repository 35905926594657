import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler'
import User from '~/img/icons/user.svg'
import Company from '~/img/icons/company.svg'
import { Link } from 'react-router-dom'
import Language from '../Language'
import { ROUTE_NAMES, ROUTE_PATHS } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import { SETTINGS_WORKSPACES } from '~/js/models/Settings'
import ModalAdditionalSignatures from '../ModalAdditionalSignatures/ModalAdditionalSignatures'
import MobileHeader from '~/js/containers/MobileHeader'
import ModalAdditionalAndGlobalSignatures from '~/js/components/ModalAdditionalAndGlobalSignatures'
import { findSignatureProducts, getGlobalProductsList } from '../../utils/common'
import * as tawkTo from '../../bootstrap/tawk.to'
import { AUTH_TYPE_ZEALID } from '../../services/user/user'

class Sidebar extends React.Component {
  constructor(props) {
    super(props)

    this.sideBarTotalHeight = React.createRef()

    this.state = {
      workspacesHidden: true,
      customLogoUrl: props.user.logoUrl,
      modalAdditionalSignatures: { active: false },
      modalAdditionalAndGlobalSignatures: { active: false }
    }

    this.onBuyAdditionalSignaturesClick = this.onBuyAdditionalSignaturesClick.bind(this)
    this.closeAdditionalSignaturesModal = this.closeAdditionalSignaturesModal.bind(this)
    this.onBuyClick = this.onBuyClick.bind(this)
    this.shrinkSideBar = this.shrinkSideBar.bind(this)
    this.showWorkspaces = this.showWorkspaces.bind(this)
    this.onWorkspaceSwitch = this.onWorkspaceSwitch.bind(this)
    this.onNewWorkspaceClick = this.onNewWorkspaceClick.bind(this)
    this.closeAdditionalAndGlobalSignaturesModal = this.closeAdditionalAndGlobalSignaturesModal.bind(this)
    this.isGlobalUser = this.isGlobalUser.bind(this)
  }

  componentDidMount() {
    tawkTo.embedScript(tawkTo.hideWidget)
  }

  onBuyAdditionalSignaturesClick() {
    if (this.isGlobalUser()) {
      this.setState({ modalAdditionalSignatures: { active: true } })
    } else {
      this.setState({ modalAdditionalAndGlobalSignatures: { active: true } })
    }
  }

  closeAdditionalSignaturesModal() {
    this.setState({ modalAdditionalSignatures: { active: false } })
  }

  closeAdditionalAndGlobalSignaturesModal() {
    this.setState({ modalAdditionalAndGlobalSignatures: { active: false } })
  }

  onBuyClick(selectedProductName, selectedProductPrice, selectedProductId, selectedProductCategory) {
    const product = this.props.products.find(itm => itm.id === selectedProductId)

    this.props.clearCart()
    this.props.addCartItem(product)
    this.props.history.push(
      selectedProductCategory === 'signature' ?
        generatePath(ROUTE_NAMES.USER_PRICING_CHECKOUT) :
        generatePath(ROUTE_NAMES.SUBSCRIPTION_WORKSPACE_ASSIGN)
    )
  }

  shrinkSideBar() {
    const main = document.querySelector('main')
    const bottomBar = document.querySelector('#bottom-bar')
    const sideDocumentPreview = document.querySelector('#side-document-preview')

    main.classList.toggle('shrink')
    if (bottomBar) {
      bottomBar.classList.toggle('shrink')
    }
    if (sideDocumentPreview) {
      sideDocumentPreview.classList.toggle('shrink')
    }

    this.props.toggleSidebarShrink()
  }

  showWorkspaces(e) {
    e.preventDefault()

    this.setState(prevState => ({ workspacesHidden: !prevState.workspacesHidden }))

    const sideBarTotalHeight = this.sideBarTotalHeight.current.clientHeight
    const offsetBtn = document.querySelector('#js-sidebar__user')
    const pageViewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    const usersListHeight = document.querySelector('#js-sidebar-more-users').offsetHeight

    if (sideBarTotalHeight < usersListHeight || pageViewportHeight <= usersListHeight) {
      document.querySelector('#js-sidebar-more-users').style.top = `${pageViewportHeight - usersListHeight}px`
    } else {
      document.querySelector('#js-sidebar-more-users').style.top = `${offsetBtn.offsetTop}px`
    }
  }

  onWorkspaceSwitch(workspace) {
    if (!workspace.active && workspace.canSwitchTo) {
      this.props.saveDocumentPaginationHistoryInfo(1, 10, 10)
      this.props.saveDocumentSelectedDocumentsHistoryInfo([])
      this.props.saveSelectedCanDeleteSelectedDocumentsInfo([])
      this.props.onWorkspaceSwitch(workspace.id)
    }
  }

  onNewWorkspaceClick() {
    if (this.props.history.location.pathname === generatePath(ROUTE_NAMES.USER_PRICING)) {
      this.setState({ workspacesHidden: true })
    }
  }

  isGlobalUser() {
    const { user } = this.props
    const countries = ['LT', 'LV', 'EE']

    return !countries.includes(user.country) && user.authMethod === AUTH_TYPE_ZEALID
  }

  render() {
    const { workspacesHidden } = this.state
    const { t, workspaces, user, signatureProducts, isSidebarShrinked } = this.props
    const activeWorkspace = workspaces.find(workspace => workspace.active)

    const totalSignatures = user.boughtSignatures + user.subscriptionSignaturesLeft
    let signaturesLeftElm

    if (user.subscriptionPayType === 'prepaid') {
      const subscriptionSignaturesLimit = user.subscriptionSignaturesLimit + user.boughtSignatures
      signaturesLeftElm = t('signatures_left') + `${totalSignatures}/${subscriptionSignaturesLimit}`
    } else if (user.subscriptionPayType === 'postpaid') {
      signaturesLeftElm = t('signatures_left') + t('unlimited').toLowerCase()
    }

    const validationInfoElm = (user.validation.allowed > 0 || user.subscription === 'start') ? `${t('document_validations')}: ${user.validation.left}/${user.validation.allowed}` : null

    return (
      <>
        <aside id="sidebar" className={`${isSidebarShrinked ? 'shrink' : ''}`} ref={this.sideBarTotalHeight}>
          <div className="sidebar__content">
            <div className={`sidebar__top ${workspacesHidden ? '' : 'js-show'}`} >
              <div className="sidebar__user-information">
                <Link to={generatePath(ROUTE_NAMES.USER_DASHBOARD)}>
                  <div className="sidebar__logo" hidden={isSidebarShrinked}>
                    {this.state.customLogoUrl !== '' && <img src={this.state.customLogoUrl} alt="Logo" />}
                  </div>
                </Link>
                <OutsideClickHandler onOutsideClick={() => this.setState({ workspacesHidden: true })}>
                  {activeWorkspace && (
                    <div
                      className="sidebar__user js-sidebar__user"
                      id="js-sidebar__user"
                      onClick={this.showWorkspaces}
                    >
                      <div className="sidebar__user-logo">
                        <img
                          src={activeWorkspace.isBusiness ? Company : User}
                          alt={activeWorkspace.isBusiness ? 'Company' : 'User'}
                        />
                      </div>
                      <div className="sidebar__user-info shrink-hide">
                        <div className="sidebar__user-plan-info">
                          <span className={!activeWorkspace.isBusiness ? 'capitalized-text' : null}>{activeWorkspace.isBusiness ? activeWorkspace.name : activeWorkspace.name.toLowerCase()}</span>
                          {!this.isGlobalUser() && <p>{signaturesLeftElm}</p>}
                          {user.subscription !== 'flexible' && !this.isGlobalUser() &&
                          <p>{t('user.pricing.additional_signatures')}: {user.boughtSignatures || 0}</p>
                          }
                          {(user.subscription !== 'flexible' && (user.globalSignatures > 0 || user.authMethod === AUTH_TYPE_ZEALID)) &&
                          <p>{this.isGlobalUser() ? t('signatures_left') : t('user.pricing.global_signatures_left')} {user.globalSignatures}</p>
                          }
                          {validationInfoElm && <p>{validationInfoElm}</p>}
                        </div>
                        <p>{t('user.settings.current_plan')}: {t(`user.pricing.${user.subscription}`)}</p>
                      </div>
                    </div>
                  )}
                  <div className="sidebar__user--more-users js-sidebar-more-users" id="js-sidebar-more-users">
                    <ul className="sidebar__user-list">
                      {workspaces.map(workspace => {
                        let userClassName = 'sidebar__user-item'

                        if (workspace.active) {
                          userClassName += ' sidebar__user--active'
                        } else if (!workspace.canSwitchTo) {
                          userClassName += ' sidebar__user--disabled'
                        }

                        return (
                          <li key={`s-workspace-${workspace.id}`}>
                            <a
                              className={userClassName}
                              href="#"
                              onClick={e => {
                                e.preventDefault()
                                this.onWorkspaceSwitch(workspace)
                              }}
                            >
                              <div className="sidebar__users-logo">
                                <img
                                  src={workspace.isBusiness ? Company : User}
                                  alt={workspace.isBusiness ? 'Company' : 'User'}
                                />
                              </div>
                              <div className="sidebar__user-info">
                                <span className={!workspace.isBusiness ? 'capitalized-text' : null}>{workspace.isBusiness ? workspace.name : workspace.name.toLowerCase()}</span>
                              </div>
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                    <div className="sidebar__user-new">
                      <Link
                        id="user__add-new"
                        to={generatePath(ROUTE_NAMES.USER_PRICING)}
                        onClick={this.onNewWorkspaceClick}
                      >
                        {t('new_workspace')}
                      </Link>
                      <Link
                        id="user__view-all"
                        to={generatePath(ROUTE_NAMES.USER_SETTINGS, { category: SETTINGS_WORKSPACES })}
                      >
                        {t('view_all')}
                      </Link>
                    </div>
                  </div>
                </OutsideClickHandler>
              </div>
              <ul className="sidebar__menu">
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--documents
                  ${(this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS) || this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_PREVIEW) || this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENT_PREVIEW)) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_DOCUMENTS)}
                  >
                    <p className="shrink-hide">{t('user.documents')}</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--validate-document ${(this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE) || this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_DOCUMENTS_VALIDATE_PREVIEW)) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_DOCUMENTS_UPLOAD_VALIDATE)}
                  >
                    <p className="shrink-hide">{t('validate_document')}</p>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="sidebar__bottom">
              <ul className="sidebar__menu">
                <li>
                  {(user.subscription === 'start' || user.subscription === 'no_plan') && (
                    <div className="sidebar__upgrade start">
                      <Link className="btn btn--green" to={generatePath(ROUTE_NAMES.USER_PRICING)}>
                        {t('user.settings.upgrade_plan')}
                      </Link>
                      <span className="btn btn--secondary" onClick={() => this.onBuyAdditionalSignaturesClick()}>
                        {this.isGlobalUser() ? t('user.settings.buy_signatures') : t('user.pricing.buy_more_signatures')}
                      </span>
                    </div>
                  )}
                  {(user.subscription === 'teams' || user.subscription === 'professional') && (
                    <div className="sidebar__upgrade">
                      <span className="btn btn--green" onClick={() => this.onBuyAdditionalSignaturesClick()}>
                        {this.isGlobalUser() ? t('user.settings.buy_signatures') : t('user.pricing.buy_more_signatures')}
                      </span>
                    </div>
                  )}
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--pricing ${this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_PRICING) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_PRICING)}
                  >
                    <p className="shrink-hide">{t('pricing')}</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--global-signatures ${this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_GLOBAL_SIGNATURES) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_GLOBAL_SIGNATURES)}
                  >
                    <p className="shrink-hide">{ t('user.pricing.signing_with_zealiD') }</p>
                  </Link>
                </li>
                <li>
                  <Link
                    className={`sidebar__menu-item sidebar__menu-item--settings ${this.props.path === ROUTE_PATHS.get(ROUTE_NAMES.USER_SETTINGS) ? 'sidebar__menu-item--active' : ''}`}
                    to={generatePath(ROUTE_NAMES.USER_SETTINGS)}
                  >
                    <p className="shrink-hide">{t('settings')}</p>
                  </Link>
                </li>
                <li>
                  <a
                    className="sidebar__menu-item sidebar__menu-item--faq"
                    href={t('helpdesk.landing')}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p className="shrink-hide">{t('faq')}</p>
                  </a>
                </li>
                <li>
                  <div className="sidebar__menu-item sidebar__menu-item--messenger" onClick={tawkTo.toggleLiveChat}>{t('messenger')}</div>
                </li>
                <li>
                  <Language />
                </li>
                <li>
                  <a
                    className="validate-document sidebar__menu-item sidebar__menu-item--logout"
                    href={generatePath(ROUTE_NAMES.USER_LOGOUT)}
                  >
                    <p className="shrink-hide">{t('logout')}</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div id="shrink-sidebar" onClick={this.shrinkSideBar} />
        </aside>
        <MobileHeader
          history={this.props.history}
          canShowWorkspaceSwitch={true}
          onWorkspaceSwitch={this.props.onWorkspaceSwitch}
          path={this.props.path}
        />
        <ModalAdditionalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalSignatures.active}
          onCloseClick={this.closeAdditionalSignaturesModal}
          signaturesProducts={signatureProducts.filter(p => p.categories.includes('global'))}
          onBuyClick={this.onBuyClick}
        />
        <ModalAdditionalAndGlobalSignatures
          t={t}
          user={user}
          active={this.state.modalAdditionalAndGlobalSignatures.active}
          onCloseClick={this.closeAdditionalAndGlobalSignaturesModal}
          signaturesProducts={findSignatureProducts(signatureProducts, user.subscription)}
          globalSignaturesProducts={getGlobalProductsList(user.subscription, signatureProducts)}
          onBuyClick={this.onBuyClick}
        />
      </>
    )
  }
}

Sidebar.propTypes = {
  user: PropTypes.object,
  clearCart: PropTypes.func,
  addCartItem: PropTypes.func,
  history: PropTypes.object,
  onWorkspaceSwitch: PropTypes.func,
  t: PropTypes.func,
  workspaces: PropTypes.array,
  path: PropTypes.string,
  products: PropTypes.array,
  signatureProducts: PropTypes.array,
  saveDocumentPaginationHistoryInfo: PropTypes.func,
  saveDocumentSelectedDocumentsHistoryInfo: PropTypes.func,
  saveSelectedCanDeleteSelectedDocumentsInfo: PropTypes.func,
  toggleSidebarShrink: PropTypes.func,
  isSidebarShrinked: PropTypes.bool,
}

export default withTranslation()(Sidebar)
