import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Select from '~/js/components/Select'
import { ErrorMessage, Field, Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import ControlButtons, { ControlButton } from '~/js/components/ControlButtons'
import Section from '~/js/components/Section'
import { SM_TYPE_SUCCESS, SM_TYPE_ERROR } from '~/js/components/StatusMessages'
import * as userService from '~/js/services/user/user'
import * as billingService from '~/js/utils/billing'
import * as settingsService from '~/js/services/user/settings'
import { formatDate, formatDateTime } from '~/js/utils/date'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath } from '~/js/routes/router'
import apiClient from '~/js/utils/apiClientDocs'
import Sign from '~/img/illustration/sign.svg'
import { getUserWorkspace } from '../../routes/router'
import ModalCancelPayments from '../ModalCancelPayments'
import Tooltip from '~/js/components/Tooltip'
import ModalCreditCard from '../ModalCreditCard'
import PropTypes from 'prop-types'
import withEmailValidation from '~/js/hoc/withEmailValidation'

const PERSON_TYPE_PRIVATE = 'private'
const PERSON_TYPE_JURIDICAL = 'juridical'

function FormGroupSelectCountry() {
  const { t, i18n } = useTranslation()
  const [ countries, setCountries ] = useState(null)
  const loadCountries = () => {
    apiClient.get('/v1/country')
      .then(({ data: { data: dataCountries } }) => setCountries(dataCountries))
  }
  const getCountryLabel = country => {
    const lang = typeof countries[i18n.language] !== 'undefined' ? i18n.language : 'en'

    return countries[lang][country]
  }
  const options = useMemo(() => {
    const enCountries = countries === null ? {} : countries.en

    return Object.keys(enCountries).map(c => ({
      value: c,
      label: getCountryLabel(c),
    }))
  }, [ countries, i18n.language ])

  useEffect(loadCountries, [])

  if (countries === null) {
    return null
  }

  return (
    <Field name="country">
      {({ field, form, meta }) => {
        const defaultOption = options.find(opt => opt.value === field.value)

        return (
          <div className={'form-group form-group--fixed-size form-group--select' + (meta.touched && meta.error ? ' form-group--error' : '')}>
            <Select
              name="country"
              options={options}
              placeholder={t('common.select')}
              defaultValue={defaultOption}
              onChange={option => form.setFieldValue('country', option.value)}
              onBlur={field.onBlur}
            />
            <ErrorMessage className="form-group__error" name="country" component="span" />
          </div>
        )
      }}
    </Field>
  )
}

function FormGroupText({ name, disabled = false }) {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className={'form-group form-group--fixed-size' + (meta.touched && meta.error ? ' form-group--error' : '')}>
          <input className="form-group__input" type="text" {...field} disabled={disabled} />
          <ErrorMessage className="form-group__error" name={field.name} component="span" />
        </div>
      )}
    </Field>
  )
}

FormGroupText.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
}

function InfoDetailsItem({ label, info }) {
  return (
    <li>
      <div className="info-details__label info-details__label--shorter">
        <label>{label}</label>
      </div>
      <div className="info-details__info">
        {info}
      </div>
    </li>
  )
}

InfoDetailsItem.propTypes = {
  label: PropTypes.string,
  info: PropTypes.any,
}

function FormBilling({ t, isCompany, handleChange, onPersonTypeChange }) {
  return (
    <Form>
      <div className="info-details info-details--standard">
        <ul key="info-details-list">
          <InfoDetailsItem
            label={t('user.person_type')} info={(
              <>
                <div className="form-group__radio">
                  <Field
                    name="personType"
                    id="personType1"
                    className="popup-radio-value"
                    type="radio"
                    value={PERSON_TYPE_PRIVATE}
                    onChange={(e) => { handleChange(e); onPersonTypeChange(PERSON_TYPE_PRIVATE) }}
                  />
                  <label htmlFor="personType1">{t('user.pricing.private')}</label>
                </div>
                <div className="form-group__radio">
                  <Field
                    name="personType"
                    id="personType2"
                    className="popup-radio-value"
                    type="radio"
                    value={PERSON_TYPE_JURIDICAL}
                    onChange={(e) => { handleChange(e); onPersonTypeChange(PERSON_TYPE_JURIDICAL) }}
                  />
                  <label htmlFor="personType2">{t('user.pricing.juridical')}</label>
                </div>
              </>
            )}
          />
          {isCompany
            ? (
              <>
                <InfoDetailsItem label={t('user.settings.workspace.company_name')} info={<FormGroupText name="companyName" />} />
                <InfoDetailsItem label={t('user.settings.workspace.registration_code')} info={<FormGroupText name="companyCode" />} />
                <InfoDetailsItem label={t('common.address')} info={<FormGroupText name="address" />} />
                <InfoDetailsItem label={t('common.city')} info={<FormGroupText name="city" />} />
                <InfoDetailsItem label={t('common.postal_code')} info={<FormGroupText name="zip" />} />
                <InfoDetailsItem label={t('common.country')} info={<FormGroupSelectCountry />} />
                <InfoDetailsItem label={t('common.email')} info={<FormGroupText name="email" />} />
                <InfoDetailsItem label={t('user.pricing.vat_number')} info={<FormGroupText name="vatNumber" />} />
              </>
            ) : (
              <>
                <InfoDetailsItem label={t('common.fullName')} info={<FormGroupText name="fullName" disabled={true} />} />
                <InfoDetailsItem label={t('user.login.person_code')} info={<FormGroupText name="personCode" disabled={true} />} />
                <InfoDetailsItem label={t('common.address')} info={<FormGroupText name="address" />} />
                <InfoDetailsItem label={t('common.city')} info={<FormGroupText name="city" />} />
                <InfoDetailsItem label={t('common.postal_code')} info={<FormGroupText name="zip" />} />
                <InfoDetailsItem label={t('common.country')} info={<FormGroupSelectCountry />} />
                <InfoDetailsItem label={t('common.email')} info={<FormGroupText name="email" />} />
              </>
            )}
        </ul>
      </div>
    </Form>
  )
}

FormBilling.propTypes = {
  t: PropTypes.func,
  isCompany: PropTypes.bool,
  handleChange: PropTypes.func,
  onPersonTypeChange: PropTypes.func,
}

const MemoizedFormBilling = React.memo(FormBilling)

function FormikBilling({ t, isCompany, billingValues, onSubmit, children, isValidEmail }) {
  const initialValues = {
    ...billingValues,
  }
  const validationSchema = React.useMemo(() => {
    let schema = Yup.object().shape({
      email: Yup.string()
        .required(t('validation.field_is_required'))
        .email(t('validation.value_is_not_a_valid_email'))
        .test('validEmailAddressRequired', t('validation.value_is_not_a_valid_email'), isValidEmail),
      address: Yup.string(),
      city: Yup.string(),
      zip: Yup.string(),
      country: Yup.string(),
    })

    if (isCompany) {
      schema = schema.concat(Yup.object().shape({
        companyName: Yup.string().required(t('validation.field_is_required')),
        companyCode: Yup.string().required(t('validation.field_is_required')),
      }))
    }

    return schema
  }, [isCompany])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {children}
    </Formik>
  )
}

FormikBilling.propTypes = {
  t: PropTypes.func,
  isCompany: PropTypes.bool,
  billingValues: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  isValidEmail: PropTypes.func,
}

function BottomBar() {
  const { submitForm, isSubmitting } = useFormikContext()
  const { t } = useTranslation()

  return (
    <div id="bottom-bar" className="bottom-bar">
      <button
        type="submit"
        className={`btn btn--primary ta-center${isSubmitting ? ' loading' : ''}`}
        disabled={isSubmitting}
        onClick={submitForm}
      >
        {t('common.save')}
      </button>
    </div>
  )
}

class SettingsBilling extends React.Component {
  constructor(props) {
    super(props)

    const { user } = props

    this.state = {
      isFetchingData: true,
      billingValues: {
        personType: PERSON_TYPE_PRIVATE,
        fullName: user.fullName,
        personCode: user.personalCode,
        email: user.email,
        address: '',
        city: '',
        zip: '',
        country: '',
        companyName: '',
        companyCode: '',
        vatNumber: '',
      },
      invoices: [],
      licences: [],
      modalCancelPayments: {
        active: false
      },
      modalCreditCard: {
        active: false
      },
      card: {
        brand: '',
        last4: ''
      }
    }
    this.fetchData = this.fetchData.bind(this)
    this.loadCardDetails = this.loadCardDetails.bind(this)
    this.updateCardDetails = this.updateCardDetails.bind(this)
    this.onSave = this.onSave.bind(this)
    this.onPersonTypeChange = this.onPersonTypeChange.bind(this)
    this.cancelPayments = this.cancelPayments.bind(this)
    this.onCancelPaymentsClick = this.onCancelPaymentsClick.bind(this)
    this.reactivateSubscription = this.reactivateSubscription.bind(this)
    this.onUpdateCreditCardClick = this.onUpdateCreditCardClick.bind(this)

    this.fetchData()
    this.loadCardDetails()
  }

  fetchData() {
    const { user, showStatusMessage } = this.props
    const licencesPromise = user.workspaceId
      ? settingsService.getAllBusinessLicenses()
      : Promise.resolve({ data: [] })

    return Promise.all([
      userService.getBillingDetails(),
      userService.getInvoicesList(),
      licencesPromise,
    ])
      .then(([ { data: billingData }, { data: invoices }, { data: licences } ]) => {
        this.setState(prevState => ({
          isFetchingData: false,
          billingValues: {
            personType: prevState.billingValues.personType,
            fullName: billingData.fullName || prevState.billingValues.fullName,
            personCode: billingData.personCode || prevState.billingValues.personCode,
            email: billingData.email || prevState.billingValues.email,
            address: billingData.address || '',
            city: billingData.city || '',
            zip: billingData.zip || '',
            country: billingData.country || '',
            companyName: billingData.companyName || '',
            companyCode: billingData.companyCode || '',
            vatNumber: billingData.vatNumber || '',
          },
          invoices,
          licences
        }))
      })
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  loadCardDetails() {
    if (this.props.user.stripeCustomerId) {
      apiClient
        .get(`v1/${getUserWorkspace()}/stripe/card-details/${this.props.user.stripeCustomerId}`)
        .then(({ data }) => this.setState({
          card: {
            brand: data.data.cardBrand,
            last4: data.data.last4,
            expMonth: data.data.expMonth,
            expYear: data.data.expYear
          }
        }))
    }
  }

  updateCardDetails([cardHolderName, cardNumber, expMonth, expYear, cvc]) {
    const { t } = this.props

    if (this.props.user.stripeCustomerId) {
      apiClient
        .post(`v1/${getUserWorkspace()}/stripe/card-details/update/${this.props.user.stripeCustomerId}`, {
          cardHolderName: cardHolderName,
          cardNumber: cardNumber,
          expMonth: expMonth,
          expYear: expYear,
          cvc: cvc
        })
        .then(res => {
          return res.data
        })
        .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.credit_card_details_were_successfully_updated')))
        .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
        .finally(() => {
          this.setState({ modalCreditCard: { active: false } })
          this.props.fetchUser()
        })
    }
  }

  onSave(params, { setSubmitting }) {
    const { t, showStatusMessage } = this.props

    userService
      .updateBillingDetails({
        email: params.email,
        address: params.address,
        city: params.city,
        zip: params.zip,
        country: params.country,
        companyName: params.companyName,
        companyCode: params.companyCode,
        vatNumber: params.vatNumber,
      })
      .then(() => showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.settings_were_successfully_changed')))
      .catch(err => showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => {
        setSubmitting(false)
      })
  }

  isCompany(personType) {
    return personType === PERSON_TYPE_JURIDICAL
  }

  onPersonTypeChange(newPersonType) {
    const { billingValues } = this.state
    billingValues.personType = newPersonType

    this.setState(billingValues)
  }

  onInvoiceDownloadClick(invoiceId) {
    const url = userService.getInvoicePdfUrl(invoiceId)

    window.open(url, '_blank')
  }

  cancelPayments() {
    const { t, user } = this.props
    if (user.subscriptionIsTrial) {
      const transactionId = user.stripeTransactionId
      this.pushGTagRefund(transactionId)
    }

    return apiClient
      .post(`v1/${getUserWorkspace()}/${this.props.user.workspaceId ? 'licenses' : 'subscription'}/cancel`)
      .then(() => {
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.payments_were_successfully_cancelled'))
        this.setState({ modalCancelPayments: { active: false } })
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.props.fetchUser())
  }

  pushGTagRefund(transactionId) {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'eec.refund',
      ecommerce: {
        refund: {
          actionField: {
            id: transactionId
          }
        }
      }
    })
  }

  onCancelPaymentsClick() {
    this.setState({ modalCancelPayments: { active: true } })
  }

  reactivateSubscription() {
    const { t } = this.props

    return apiClient
      .post(`v1/${getUserWorkspace()}/subscription/reactivate`)
      .then(() => {
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.settings.subscription_was_successfully_reactivated'))
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.props.fetchUser())
  }

  onUpdateCreditCardClick() {
    this.setState({ modalCreditCard: { active: true } })
  }

  render() {
    const { t, user } = this.props
    const { billingValues, invoices, licences, card } = this.state
    user.totalLicenses = licences.length

    if (this.state.isFetchingData) {
      return null
    }

    return (
      <React.Fragment>
        <FormikBilling
          t={t}
          isCompany={this.isCompany(billingValues.personType)}
          billingValues={billingValues}
          onSubmit={this.onSave}
          isValidEmail={this.props.isValidEmail}
        >
          {formikProps => (
            <div className="grid grid--full grid--no-gutter billing">
              <div className={`row billing__row-plan${(user.subscription === 'teams' || user.subscription === 'professional') && !user.subscriptionIsTerminated ? '-business' : ''}`}>
                <div className="col billing__col-plan">
                  <Section className="section--plan">
                    <h2 className="headline headline--standard text-sm ta-center">{t('user.settings.your_plan')}</h2>
                    <div className="headline headline--standard headline--plan ta-center">{t(`user.pricing.${user.subscription}`)}</div>
                    <div className="info-details info-details--w50 info-details--plan-details text-sm">
                      <ul>
                        {user.subscription !== 'limited_flexible' && (
                          <React.Fragment>
                            <li>
                              <div className="info-details__label">
                                <label className="billing__signatures-tooltip">
                                  {t('user.settings.monthly_signatures_limit')}
                                  <Tooltip text={t('user.settings.monthly_signatures_limit_information')} newLine={true}>
                                    <span className="info-icon" />
                                  </Tooltip>
                                </label>
                              </div>
                              <div className="info-details__info">
                                <p>
                                  {user.subscription !== 'flexible' ?
                                    user.subscriptionSignaturesLimit :
                                    t('unlimited')}
                                </p>
                              </div>
                            </li>
                            {user.subscription !== 'flexible' &&
                              <li>
                                <div className="info-details__label">
                                  <label>{t('user.settings.bought_signatures_left')}</label>
                                </div>
                                <div className="info-details__info">
                                  {
                                    user.boughtSignatures > 0 ?
                                      <p>{user.boughtSignatures}</p> :
                                      <Link style={{ color: '#0096C7', fontWeight: '500' }} to={generatePath(ROUTE_NAMES.USER_PRICING)}>{t('user.settings.purchase_signatures')}</Link>
                                  }
                                </div>
                              </li>
                            }
                          </React.Fragment>
                        )}
                        {(user.subscription !== 'flexible' && user.subscription !== 'no_plan') &&
                        <li>
                          <div className="info-details__label">
                            <label>{t('user.settings.subscription_expiration_date')}</label>
                          </div>
                          <div className="info-details__info">
                            <p>
                              {user.subscription === 'start' ? t('unlimited') : user.expirationDate}
                            </p>
                          </div>
                        </li>
                        }
                        {billingService.doesUserHaveBillingDate(user) &&
                          <>
                            {!user.subscriptionIsTerminated && user.monthlyUsageResetDate &&
                              <li>
                                <div className="info-details__label">
                                  <label>{t('user.settings.monthly_signatures_usage_reset_date')}</label>
                                </div>
                                <div className="info-details__info">
                                  <p>{user.subscription === 'flexible' ? t('unlimited') : formatDate(user.monthlyUsageResetDate)}</p>
                                </div>
                              </li>
                            }
                            {billingService.isUserPlanSubscriptionBased(user) &&
                              <li>
                                <div className="info-details__label">
                                  <label>{t('user.settings.next_payment_date')}</label>
                                </div>
                                <div className="info-details__info">
                                  <p>
                                    {!user.subscriptionIsTerminated ? user.expirationDate : t('user.settings.payments_are_terminated')}
                                  </p>
                                </div>
                              </li>
                            }
                          </>
                        }
                        <li>
                          {userService.isUserBusiness(user) && user.totalLicenses > 0 &&
                            <>
                              <div className="info-details__label">
                                <label>{t('user.settings.user_licences')}</label>
                              </div>
                              <div className="info-details__info">
                                <p>{user.companyUsersQuantity} / {user.totalLicenses}</p>
                              </div>
                            </>
                          }
                        </li>
                        { user.subscription === 'flexible' && (user.permissions.isAdmin || user.permissions.isPersonAdmin) && (
                          <>
                            <li>
                              <div className="info-details__label">
                                <label>{t('user.settings.signature_used_this_month')}</label>
                              </div>
                              <div className="info-details__info">
                                <p>{user.signaturesUsedThisMonth}</p>
                              </div>
                            </li>
                            <li>
                              <div className="info-details__label">
                                <label>{t('user.settings.global_signature_used_this_month')}</label>
                              </div>
                              <div className="info-details__info">
                                <p>{user.globalSignaturesUsedThisMonth}</p>
                              </div>
                            </li>
                          </>
                        )}
                        { user.subscription === 'flexible' && (
                          <li>
                            <div className="info-details__label">
                              <label>{t('user.settings.sms_used_this_month')}</label>
                            </div>
                            <div className="info-details__info">
                              <p>{user.smsUsedThisMonth}</p>
                            </div>
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="center-element center-element--space-between">
                      {user.subscription !== 'flexible' &&
                        <Link className="btn btn--secondary" to={generatePath(ROUTE_NAMES.USER_PRICING)}>{t('user.settings.upgrade')}</Link>
                      }
                      {!user.subscriptionIsTerminated && user.subscription === 'professional' && (
                        <button
                          className="btn btn--medium-grey"
                          onClick={() => this.onCancelPaymentsClick()}
                        >
                          {t('user.settings.cancel_payments')}
                        </button>
                      )}
                      {user.subscriptionIsTerminated && user.subscription === 'professional' && (
                        <button
                          className="btn btn--green"
                          onClick={() => this.reactivateSubscription()}
                        >
                          {t('user.settings.reactivate_subscription')}
                        </button>
                      )}
                      {user.workspaceId && user.subscription === 'teams' && (
                        <Link
                          className="btn btn--green"
                          to={{
                            pathname: generatePath(ROUTE_NAMES.USER_USERS_QUANTITY),
                            state: { licencesCount: licences.length, companyUsersQuantity: user.companyUsersQuantity, country: billingValues.country }
                          }}
                        >
                          {t('user.settings.company_user.change_users_quantity')}
                        </Link>
                      )}
                    </div>
                    <div className="center-element" style={{ marginTop: '10px' }}>
                      {!user.subscriptionIsTerminated && user.subscription === 'teams' && (
                        <button
                          className="btn btn--medium-grey"
                          onClick={() => this.onCancelPaymentsClick()}
                        >
                          {t('user.settings.cancel_payments')}
                        </button>
                      )}
                    </div>
                  </Section>
                </div>
                <div className="col billing__col-plan">
                  <div className="grid grid--no-gutter grid--flex">
                    {user.subscription !== 'limited_flexible' && (
                      <Section
                        className="billing__counter"
                        title={t('user.settings.signatures_left_this_month')}
                      >
                        <div className="ta-center" style={{ marginTop: '24px' }}>
                          <span className="text-2xl">
                            {user.subscription !== 'flexible' ?
                              (user.subscriptionSignaturesLeft > 0 ? user.subscriptionSignaturesLeft : 0) + '/' + user.subscriptionSignaturesLimit :
                              t('unlimited')
                            }
                          </span>
                        </div>
                      </Section>
                    )}
                    {user.subscription !== 'flexible' && (
                      <Section className={`billing__${(user.subscription === 'teams' || user.subscription === 'professional') && !user.subscriptionIsTerminated ? 'cta-business' : 'cta'}`}>
                        <div>
                          <h2>{t('user.pricing.cta_more_signed_documents')}</h2>
                          <p>{t('user.pricing.cta_more_signatures')}</p>
                          <Link to={generatePath(ROUTE_NAMES.USER_PRICING)}>{t('user.pricing.cta_chose_plan')}</Link>
                        </div>
                        <div><img src={Sign} alt="Icon Sign" /></div>
                      </Section>
                    )}
                  </div>
                </div>
              </div>
              {card.last4 && (
                <div className="row">
                  <div className="col lg-10-12 xl-8-12 billing__user-information">
                    <Section title={t('user.pricing.credit_card_details')}>
                      <div className="info-details info-details--standard">
                        <ul>
                          <li>
                            <div className="info-details__label info-details__label--shorter">
                              <label>{t('user.pricing.card_name')}</label>
                            </div>
                            <div className="info-details__info">
                              <p>{card.brand}</p>
                            </div>
                          </li>
                          <li>
                            <div className="info-details__label info-details__label--shorter">
                              <label>{t('user.pricing.card_number')}</label>
                            </div>
                            <div className="info-details__info">
                              <p>**** **** **** {card.last4}</p>
                            </div>
                          </li>
                          <li>
                            <div className="info-details__label info-details__label--shorter">
                              <label>{t('user.pricing.card_expiry_date')}</label>
                            </div>
                            <div className="info-details__info">
                              <p>{card.expMonth}/{card.expYear}</p>
                            </div>
                          </li>
                        </ul>
                        <br />
                        <button
                          className="btn btn--secondary"
                          onClick={() => this.onUpdateCreditCardClick()}
                        >
                          {t('user.pricing.update_credit_card')}
                        </button>
                      </div>
                    </Section>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col lg-10-12 xl-8-12 billing__user-information">
                  <Section title={t('user.pricing.billing_information')}>
                    <MemoizedFormBilling
                      t={t}
                      isCompany={this.isCompany(formikProps.values.personType)}
                      handleChange={formikProps.handleChange}
                      onPersonTypeChange={this.onPersonTypeChange}
                    />
                  </Section>
                </div>
              </div>
              {invoices.length > 0 && (
                <div className="row">
                  <div className="col lg-10-12 xl-8-12" style={{ marginTop: '24px' }}>
                    <Section title={t('user.settings.invoices')}>
                      <div className="table" style={{ marginTop: '24px' }}>
                        <ul className="table__head">
                          <li>{t('user.settings.invoice_number')}</li>
                          <li>{t('user.settings.order_id')}</li>
                          <li>{t('common.date')}</li>
                          <li>{t('user.pricing.total')}</li>
                          <li />
                        </ul>
                        <ul className="table__body">
                          {invoices.map((invoice) => (
                            <li key={`invoice-${invoice.invoiceId}`}>
                              <div className="table__body__col"><p><strong>{invoice.number}</strong></p></div>
                              <div className="table__body__col"><p><strong>{invoice.orderId}</strong></p></div>
                              <div className="table__body__col">{formatDateTime(invoice.date)}</div>
                              <div className="table__body__col">{invoice.totalSum}</div>
                              <div className="table__body__col table__body__col--overflow table--download-invoice">
                                <ControlButtons active={true}>
                                  <ControlButton
                                    type="download"
                                    title={t('common.download')}
                                    showLabel={false}
                                    showTooltip={true}
                                    onClick={() => this.onInvoiceDownloadClick(invoice.invoiceId)}
                                  />
                                </ControlButtons>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Section>
                  </div>
                </div>
              )}
              <BottomBar t={t} />
            </div>
          )}
        </FormikBilling>
        <ModalCancelPayments
          active={this.state.modalCancelPayments.active}
          title={t('user.settings.cancel_payments')}
          message={t('user.settings.are_you_sure_you_want_to_cancel_payments')}
          onCloseClick={() => this.setState({ modalCancelPayments: { active: false } })}
          onCancelPaymentsClick={this.cancelPayments}
        />
        <ModalCreditCard
          t={t}
          active={this.state.modalCreditCard.active}
          title={t('user.pricing.credit_card_details')}
          updateCardDetails={this.updateCardDetails}
          onCloseClick={() => this.setState({ modalCreditCard: { active: false } })}
          onCancelPaymentsClick={this.cancelPayments}
        />
      </React.Fragment>
    )
  }
}

SettingsBilling.propTypes = {
  user: PropTypes.object,
  showStatusMessage: PropTypes.func,
  t: PropTypes.func,
  fetchUser: PropTypes.func,
  isValidEmail: PropTypes.func,
}

export default (withEmailValidation(SettingsBilling))
