import React from 'react'
import PropTypes from 'prop-types'
import DocumentViewer from '~/js/components/DocumentViewer'
import SectionDocumentInformation from '~/js/components/SectionDocumentInformation'
import * as documentService from '../../../../services/user/document'
import ModalShare from '~/js/components/ModalShare'
import Members from '../../../../components/Members'
import { SM_TYPE_ERROR, SM_TYPE_SUCCESS } from '~/js/components/StatusMessages'
import StatusMessages from '~/js/containers/StatusMessages'
import { DOCUMENT_ACCESS_PRIVATE, DOCUMENT_FORMAT_PDF, DOCUMENT_STATUS_SAVED_DRAFT, DOCUMENT_VALIDATION_PURPOSE_SIGNATURE } from '~/js/models/Document'
import { ROUTE_NAMES } from '~/js/routes/config'
import { generatePath, getUserWorkspace } from '~/js/routes/router'
import RadioButtonDocSign from '~/js/components/RadioButtonDocSign'
import apiClient from '~/js/utils/apiClientDocs'
import DocumentComments from '~/js/components/DocumentComments'
import IconTooltip from '~/js/components/IconTooltip/IconTooltip'
import ControlButtonsDocument from '../../../../components/ControlButtonsDocument'
import ModalDocumentViewer from '~/js/components/ModalDocumentViewer'
import ModalRemove from '~/js/components/ModalRemove'
import { Link } from 'react-router-dom'
import AppHeader from '~/js/components/AppHeader'
import AdditionalPerks from '~/js/components/AdditionalPerks'
import SectionErrorInformation from '../../../../components/SectionErrorInformation'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const DEFAULT_PURPOSE = 'e_signature'
const validationStatusTimeLimit = 90

export default class UploadDocumentPreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      document: undefined,
      documentErrors: [],
      modalShare: {
        access: DOCUMENT_ACCESS_PRIVATE,
        active: false
      },
      members: [],
      uploadedSignedMembers: [],
      signaturesPlacingPosition: `${props.user.defaultSignaturePosition}`,
      isSigningOrderImportant: false,
      billDocumentOwner: props.user.permissions.canSetBillDocumentOwner && props.user.checkBillDocumentOwner,
      useMyWhiteLabel: false,
      documentComments: [],
      isWidget: !!new URL(location.href).searchParams.get('widget'),
      modalPdfViewActive: false,
      memberLoading: true,
      isSaving: false
    }

    this.showStatusMessage = props.showStatusMessage
    this.getDocumentId = this.getDocumentId.bind(this)
    this.getDocumentMembers = this.getDocumentMembers.bind(this)
    this.onDocumentDownloadClick = this.onDocumentDownloadClick.bind(this)
    this.getDocumentUploadedSignedMembers = this.getDocumentUploadedSignedMembers.bind(this)
    this.addSigner = this.addSigner.bind(this)
    this.addSigners = this.addSigners.bind(this)
    this.removeSigner = this.removeSigner.bind(this)
    this.saveDocument = this.saveDocument.bind(this)
    this.saveDraft = this.saveDraft.bind(this)
    this.onAddToDocuments = this.onAddToDocuments.bind(this)
    this.onStartSigning = this.onStartSigning.bind(this)
    this.onSaveDraft = this.onSaveDraft.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onAccessChange = this.onAccessChange.bind(this)
    this.onAddNewMembersClick = this.onAddNewMembersClick.bind(this)
    this.onSigningOrderSelect = this.onSigningOrderSelect.bind(this)
    this.onBillDocumentOwnerSelect = this.onBillDocumentOwnerSelect.bind(this)
    this.fetchDocumentInfo = this.fetchDocumentInfo.bind(this)
    this.assignCategoriesToDocument = this.assignCategoriesToDocument.bind(this)
    this.unassignDocumentCategory = this.unassignDocumentCategory.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)
    this.onRadioButtonDocSignCheck = this.onRadioButtonDocSignCheck.bind(this)
    this.addComment = this.addComment.bind(this)
    this.removeComment = this.removeComment.bind(this)
    this.onDocumentViewClick = this.onDocumentViewClick.bind(this)
    this.onDocumentRemoveClick = this.onDocumentRemoveClick.bind(this)
    this.onModalRemoveDocumentClick = this.onModalRemoveDocumentClick.bind(this)
    this.addPurposeInMember = this.addPurposeInMember.bind(this)
    this.updateDocumentMemberPhone = this.updateDocumentMemberPhone.bind(this)
    this.findDocumentMembersInfo = this.findDocumentMembersInfo.bind(this)
    this.onUseMyWhiteLabelChange = this.onUseMyWhiteLabelChange.bind(this)

    this.fetchDocumentComments()

    this.cancelSignatureIntegrityRequest = null

    this.fetchDocumentInfo()
      .then(this.findDocumentMembersInfo)
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  getDocumentId() {
    return this.state.document ? this.state.document.uuid : this.props.match.params.documentId
  }

  getDocumentMembers(data) {
    return this.state.document ? this.state.members : data.members
  }

  getDocumentUploadedSignedMembers(data) {
    return this.state.document ? this.state.uploadedSignedMembers : data.uploadedSignedMembers
  }

  addSigner([name, surname, email, showBusinessPersonName, purpose, phone, language]) {
    this.setState(prevState => ({
      members: [
        ...prevState.members,
        {
          name: name,
          surname: surname,
          email: email,
          showBusinessPersonName: showBusinessPersonName,
          signStatus: null,
          purpose: purpose,
          phone: phone,
          language: language ? language : 'lt'
        }
      ],
      modalShare: {
        ...prevState.modalShare,
        active: false,
      }
    }))
  }

  addSigners(signers) {
    const newMembers = []
    signers.map(signer => {
      if (!this.state.members.find(member => member.email === signer.email)) {
        newMembers.push({
          name: signer.name,
          surname: signer.surname,
          email: signer.email,
          showBusinessPersonName: signer.showBusinessPersonName,
          signStatus: null,
          purpose: signer.purpose,
          phone: signer.phone,
          language: signer.language ? signer.language : 'lt'
        })
      }
    })

    this.setState(prevState => ({
      members: [
        ...prevState.members,...newMembers
      ],
      modalShare: {
        ...prevState.modalShare,
        active: false,
      }
    }))
  }

  removeSigner(index) {
    const array = [...this.state.members]

    array.splice(index, 1)
    this.setState({ members: array })
  }

  saveDocument() {
    const { t } = this.props
    const { document, modalShare, isSigningOrderImportant, signaturesPlacingPosition, billDocumentOwner, useMyWhiteLabel } = this.state
    const members = this.state.members.map(memberObject => {
      return memberObject.purpose === undefined || memberObject.purpose === null ? { ...memberObject, purpose: DEFAULT_PURPOSE } : memberObject
    })

    this.setState({ isSaving: true })

    return documentService.save(
      document.uuid,
      {
        access: modalShare.access,
        signers: members.map(({ name, surname, email, showBusinessPersonName, purpose, phone, language }) => ({ name, surname, email, showBusinessPersonName, purpose, phone, language })),
        isSigningOrderImportant,
        signaturesPlacingPosition,
        billDocumentOwner,
        useMyWhiteLabel,
      })
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_saved')))
      .catch(err => {
        this.props.showStatusMessage(SM_TYPE_ERROR, err.message)

        return Promise.reject(err)
      })
      .finally(() => this.setState({ isSaving: false }))
  }

  addPurposeInMember(index) {
    const members = [...this.state.members]
    return ((purpose) => {
      members[index].purpose = purpose
      this.setState({ members })
    })
  }

  saveDraft() {
    const { t } = this.props
    const { document, modalShare, isSigningOrderImportant, signaturesPlacingPosition, billDocumentOwner, useMyWhiteLabel } = this.state
    const members = this.state.members.map(memberObject => {
      return memberObject.purpose === undefined ? { ...memberObject, purpose: DEFAULT_PURPOSE } : memberObject
    })

    this.setState({ isSaving: true })

    return documentService
      .saveDraft(
        document.uuid,
        {
          access: modalShare.access,
          signers: members.map(({ name, surname, email, showBusinessPersonName, purpose }) => ({ name, surname, email, showBusinessPersonName, purpose })),
          isSigningOrderImportant,
          signaturesPlacingPosition,
          billDocumentOwner,
          useMyWhiteLabel,
        }
      )
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_draft_was_successfully_saved')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
      .finally(() => this.setState({ isSaving: false }))
  }

  onAddToDocuments() {
    return this.saveDocument()
      .then(() => {
        this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
      })
  }

  onStartSigning() {
    const { document } = this.state

    return this.saveDocument()
      .then(() => {
        this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENT_PREVIEW, { documentId: document.uuid }))
      })
  }

  onSaveDraft() {
    return this.saveDraft()
      .then(() => {
        this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
      })
  }

  onCancel() {
    const { t } = this.props

    typeof this.cancelSignatureIntegrityRequest === 'function' && this.cancelSignatureIntegrityRequest()

    return documentService
      .remove(this.props.match.params.documentId)
      .then(() => this.props.history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS)))
      .then(() => this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_removed')))
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onAccessChange(event) {
    this.setState({
      modalShare: {
        active: this.state.modalShare.active,
        access: event.currentTarget.value
      }
    })
  }

  onAddNewMembersClick() {
    this.setState({
      modalShare: {
        active: true,
        access: this.state.modalShare.access
      }
    })
  }

  isContentShrink() {
    if (document.querySelector('#sidebar').className === 'shrink') {
      document.querySelector('main').classList.toggle('shrink')
      document.querySelector('#side-document-preview').classList.toggle('shrink')
      document.querySelector('#bottom-bar').classList.toggle('shrink')
    }
  }

  onSigningOrderSelect() {
    this.setState({ isSigningOrderImportant: !this.state.isSigningOrderImportant })
  }

  onBillDocumentOwnerSelect() {
    this.setState({ billDocumentOwner: !this.state.billDocumentOwner })
  }

  onUseMyWhiteLabelChange(e) {
    this.setState({ useMyWhiteLabel: !!e.target.checked })
  }

  fetchDocumentInfo() {
    return documentService
      .getInfo(this.getDocumentId())
      .then(data => new Promise(res => {
        this.setState(prevState => ({
          document: data,
          documentErrors: data?.errors || [],
          uploadedSignedMembers: this.getDocumentUploadedSignedMembers(data),
          members: this.getDocumentMembers(data),
          isSigningOrderImportant: data.isSigningOrderImportant,
          modalShare: {
            ...prevState.modalShare,
            access: data.access,
          },
        }), res)
      }))
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  findDocumentMembersInfo(uuid) {
    uuid = uuid || this.getDocumentId()
    const { promise, cancel } = documentService.initializeDocumentValidation(uuid, DOCUMENT_VALIDATION_PURPOSE_SIGNATURE)

    this.cancelSignatureIntegrityRequest = cancel
    return promise
      .then((data) => {
        const statusRequest = documentService.pollMemberStatus(data.session_id, uuid, validationStatusTimeLimit)
        return statusRequest.promise
      })
      .then(data => {
        this.setState({
          uploadedSignedMembers: data.signers,
          memberLoading: false,
        })

      })
      .catch(err => {
        if (err.message) {
          this.showStatusMessage(SM_TYPE_ERROR, err.message)
        }

        this.setState({ memberLoading: false })
      })
  }

  assignCategoriesToDocument(categories) {
    const { t } = this.props

    const selectedCategories = []

    categories.forEach(category => {
      if (category.selected) {
        selectedCategories.push(category)
      }
    })

    if (selectedCategories.length > 0) {
      return apiClient
        .post(`v1/${getUserWorkspace()}/document/assign-category/${this.state.document.uuid}`, {
          categories: selectedCategories
        })
        .then(() => {
          this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.categories_were_successfully_assigned'))
          this.fetchDocumentInfo()
        })
        .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
    } else {
      this.props.showStatusMessage(SM_TYPE_ERROR, t('user.document.no_category_selected'))
    }
  }

  unassignDocumentCategory(category) {
    const { t } = this.props

    return apiClient
      .post(`v1/${getUserWorkspace()}/document/unassign-category/${this.state.document.uuid}/${category.id}`)
      .then(() => {
        this.props.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.category_was_successfully_unassigned'))
        this.fetchDocumentInfo()
      })
      .catch(err => this.props.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const members = reorder(
      this.state.members,
      result.source.index,
      result.destination.index
    )

    this.setState({
      members
    })
  }

  onRadioButtonDocSignCheck(value) {
    this.setState({
      signaturesPlacingPosition: value
    })
  }

  componentDidMount() {
    this.isContentShrink()
  }

  componentWillUnmount() {
    typeof this.cancelSignatureIntegrityRequest === 'function' && this.cancelSignatureIntegrityRequest()
  }

  fetchDocumentComments() {
    documentService
      .getDocumentComments(this.getDocumentId())
      .then(data => {
        this.setState({
          documentComments: data
        })
      })
      .catch(error => {
        this.showStatusMessage(SM_TYPE_ERROR, error.message)
      })
  }

  addComment([commentaryText, access]) {
    const { t } = this.props

    documentService
      .addComment(this.state.document.uuid, { commentaryText, access })
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.comment_was_successfully_added'))
        this.fetchDocumentComments()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  removeComment(id) {
    const { t } = this.props

    documentService
      .removeComment(id)
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.comment_was_successfully_removed'))
        this.fetchDocumentComments()
      })
      .catch(err => this.showStatusMessage(SM_TYPE_ERROR, err.message))
  }

  onDocumentViewClick() {
    this.setState({ modalPdfViewActive: true })
  }

  onDocumentRemoveClick() {
    this.setState({ modalRemoveActive: true })
  }

  onDocumentDownloadClick() {
    const url = `/api/v1/${getUserWorkspace()}/document/${this.getDocumentId()}/download`

    window.open(url, '_blank')
  }

  onModalRemoveDocumentClick() {
    const { t, history } = this.props

    typeof this.cancelSignatureIntegrityRequest === 'function' && this.cancelSignatureIntegrityRequest()

    documentService
      .remove(this.getDocumentId())
      .then(() => {
        this.showStatusMessage(SM_TYPE_SUCCESS, t('user.document.document_was_successfully_removed'))
        history.push(generatePath(ROUTE_NAMES.USER_DOCUMENTS))
      })
      .catch(err => {
        this.showStatusMessage(SM_TYPE_ERROR, err.message)
      })
      .finally(() => {
        this.setState({ modalRemoveActive: false })
      })
  }

  updateDocumentMemberPhone(documentId, updatedMembers) {
    const { members } = this.state

    updatedMembers.forEach((updatedMember) => {
      const memberToUpdate = members.find(mem => mem.email === updatedMember.email)
      memberToUpdate.phone = updatedMember.phone
    })
  }

  render() {
    const { t, user } = this.props
    const { document, memberLoading, isWidget, isSaving, documentErrors } = this.state

    return (
      <div id="page-container">
        <main id="preview">
          <section>
            <div className="grid">
              <div className="row">
                <div className="col md-6-12">
                  <AppHeader className="header--mobile">
                    {!isWidget ?
                      <Link
                        className="header__page-navigation"
                        to={{
                          pathname: generatePath(ROUTE_NAMES.USER_DOCUMENTS),
                          previousSearchData: this.props.location.searchValue
                        }}
                      >
                        {t('user.document.all_documents')}
                      </Link> : null}
                    {!isWidget ?
                      <div className="show-tablet">
                        <ControlButtonsDocument
                          t={t}
                          showDownload={true}
                          showRemove={document && document.permissions.canDelete}
                          onDownloadClick={this.onDocumentDownloadClick}
                          onRemoveClick={this.onDocumentRemoveClick}
                        />
                      </div> : null}
                  </AppHeader>
                  {0 < documentErrors.length && (
                    <SectionErrorInformation errors={documentErrors} />
                  )}
                  {document && (
                    <SectionDocumentInformation
                      t={t}
                      document={document}
                      deadline={document.deadline}
                      showCategories={true}
                      canEdit={true}
                      assignCategoriesToDocument={this.assignCategoriesToDocument}
                      unassignDocumentCategory={this.unassignDocumentCategory}
                      visibleDocumentCategories={this.props.user.visibleDocumentCategories}
                      user={user}
                      showStatusMessage={this.props.showStatusMessage}
                      userSubscription={this.props.user.subscription}
                      showAccess={false}
                      showDeadline={true}
                      isAdmin={this.props.user.permissions.isAdmin}
                    />
                  )}
                  <div className="wrapped-section">
                    <div className="wrapped-section__headline">
                      <h2 className="headline headline--information">{t('common.signatures')}</h2>
                    </div>
                    {document && (
                      <div className="info-details grid">
                        <div className="row">
                          {document.format === DOCUMENT_FORMAT_PDF && (
                            <div className="col">
                              <div className="info-details__label w-100">
                                <label>{t('user.document.signature_placing_area')}</label>
                              </div>
                              <div className="form-group form-group--select checkbox-selector">
                                <RadioButtonDocSign
                                  onRadioButtonDocSignCheck={this.onRadioButtonDocSignCheck}
                                  signaturesPlacingPosition={this.state.signaturesPlacingPosition}
                                  t={t}
                                />
                              </div>
                            </div>
                          )}
                          <div className={`col wrapped-section__bill-document-check ${document.format === DOCUMENT_FORMAT_PDF ? 'top-border-separator' : ''}`}>
                            <div className="w-100">
                              <label>{t('user.document.signature_pay_for_others')}</label>
                            </div>
                            <div className={'form-group__checkbox signature-deduction' + (!user.permissions.canSetBillDocumentOwner ? ' disabled-checkbox' : '')}>
                              <input
                                className="checkbox__document"
                                type="checkbox"
                                id="bill-document-owner"
                                checked={this.state.billDocumentOwner}
                                onChange={this.onBillDocumentOwnerSelect}
                                disabled={!user.permissions.canSetBillDocumentOwner}
                              />
                              <label htmlFor="bill-document-owner">
                                {t('user.document.bill_document_owner_signatures')}
                              </label>
                              {!user.permissions.canSetBillDocumentOwner && (
                                <div className="pro-icon__mobile-adjustment">
                                  <IconTooltip
                                    tooltipText={t('user.document.available_from_pro_tooltip')}
                                    inline={true}
                                    newLine={true}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {document.format === DOCUMENT_FORMAT_PDF && (
                            <div className="col wrapped-section__bill-document-check">
                              <div className="w-100 mt-3">
                                <label>{t('user.document.unique_signature_design')}</label>
                              </div>
                              <div
                                className={'form-group__checkbox signature-deduction' + (!user.hasSignatureWhiteLabel ? ' disabled-checkbox' : '')}
                              >
                                <input
                                  className="checkbox__document"
                                  type="checkbox"
                                  id="use-my-white-label"
                                  checked={this.state.useMyWhiteLabel}
                                  onChange={this.onUseMyWhiteLabelChange}
                                  disabled={!user.hasSignatureWhiteLabel}
                                />
                                <label htmlFor="use-my-white-label">
                                  {t('user.document.use_my_signature_design')}
                                </label>
                                {('flexible' !== user.subscription || !user.hasSignatureWhiteLabel) && (
                                  <div className="pro-icon__mobile-adjustment">
                                    <IconTooltip
                                      tooltipText={t('user.document.available_from_flex_tooltip')}
                                      inline={true}
                                      newLine={true}
                                      flex={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <Members
                    user={user}
                    memberLoading={memberLoading}
                    members={this.state.members}
                    uploadedSignedMembers={this.state.uploadedSignedMembers}
                    access={this.state.modalShare.access}
                    documentId={document ? document.uuid : undefined}
                    addSigner={this.addSigner}
                    addSigners={this.addSigners}
                    upload={true}
                    onAccessChange={this.onAccessChange}
                    canInviteSigner={true}
                    showStatusMessage={this.props.showStatusMessage}
                    isSigningOrderImportant={this.state.isSigningOrderImportant}
                    onSigningOrderImportantChange={this.onSigningOrderSelect}
                    onDragEnd={this.onDragEnd}
                    onRemoveClick={this.removeSigner}
                    newLine={true}
                    isBusinessPerson={!!this.props.user.workspaceId}
                    addPurposeInMember={this.addPurposeInMember}
                  />
                  {document && (
                    <AdditionalPerks
                      user={user}
                      documentId={document.uuid}
                      showStatusMessage={this.props.showStatusMessage}
                      deadline={document.deadline}
                      signForbid={document.signForbid}
                      reminderFrequency={document.reminderFrequency}
                      viaSMS={document.reminderType === 3 || document.reminderType === 1}
                      viaEmail={document.reminderType === 3 || document.reminderType === 2}
                      fetchDocumentInfo={this.fetchDocumentInfo}
                      runDeadlineCheck={false}
                      members={this.state.members}
                      updateDocumentMemberPhone={this.updateDocumentMemberPhone}
                    />
                  )}
                  {document &&
                    <DocumentComments
                      t={this.props.t}
                      document={document}
                      documentComments={this.state.documentComments}
                      addComment={this.addComment}
                      removeComment={this.removeComment}
                      user={this.props.user}
                      showStatusMessage={this.props.showStatusMessage}
                    />
                  }
                </div>
                <div className="col md-6-12">
                  <div id="side-document-preview" className={isWidget ? 'shrink' : ''}>
                    <div id="side-document-preview" className="preview__document-box">
                      {!isWidget ?
                        <div className="preview__controls">
                          <ControlButtonsDocument
                            t={t}
                            showView={true}
                            showRemove={document && document.permissions.canDelete}
                            onViewClick={this.onDocumentViewClick}
                            onRemoveClick={this.onDocumentRemoveClick}
                          />
                        </div> : null}
                      <p>{t('user.document.document_preview')}</p>
                      {document && (
                        <DocumentViewer
                          t={t}
                          document={document}
                          showStatusMessage={this.props.showStatusMessage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <div className="bottom-bar hide-tablet" id="bottom-bar">
          <button
            className={`btn btn--primary ${isSaving || memberLoading ? 'loading' : ''}`}
            disabled={isSaving || memberLoading}
            onClick={this.onStartSigning}
          >
            {t('start_signing')}
          </button>
          {document && document.status !== DOCUMENT_STATUS_SAVED_DRAFT && (
            <button
              className='btn btn--secondary'
              disabled={isSaving || memberLoading}
              onClick={this.onSaveDraft}
            >
              {t('save_draft')}
            </button>
          )}
          <button
            className={`btn btn--red ${isSaving ? 'loading' : ''}`}
            disabled={isSaving}
            onClick={this.onCancel}
          >
            {t('remove')}
          </button>
        </div>
        <div className="bottom-bar show-tablet" id="bottom-bar">
          <button
            className={`btn btn--red ${isSaving ? 'loading' : ''}`}
            disabled={isSaving}
            onClick={this.onCancel}
          >
            {t('remove')}
          </button>
          {document && document.status !== DOCUMENT_STATUS_SAVED_DRAFT && (
            <button
              className='btn btn--secondary'
              disabled={isSaving || memberLoading}
              onClick={this.onSaveDraft}
            >
              {t('save_draft')}
            </button>
          )}
          <button
            className={`btn btn--primary ${isSaving || memberLoading ? 'loading' : ''}`}
            disabled={isSaving || memberLoading}
            onClick={this.onStartSigning}
          >
            {t('start_signing')}
          </button>
        </div>
        {document && (
          <div>
            <ModalShare
              active={this.state.modalShare.active}
              access={this.state.modalShare.access}
              size="small"
              documentUuid={document.uuid}
              title={t('share_document')}
              addSigner={this.addSigner}
              onCloseClick={() => this.setState({ modalShare: { active: false, access: this.state.modalShare.access } })}
              showStatusMessage={this.props.showStatusMessage}
              isBusinessPerson={!!this.props.user.workspaceId}
              upload={true}
            />
            <ModalDocumentViewer
              t={t}
              document={document}
              showStatusMessage={this.props.showStatusMessage}
              active={this.state.modalPdfViewActive}
              onCloseClick={() => this.setState({ modalPdfViewActive: false })}
              title={t('user.document.document_preview')}
            />
            <ModalRemove
              active={this.state.modalRemoveActive}
              title={t('user.document.remove_document')}
              message={t('user.document.are_you_sure_you_want_to_remove')}
              onCloseClick={() => this.setState({ modalRemoveActive: false })}
              onRemoveClick={this.onModalRemoveDocumentClick}
            />
          </div>
        )}

        <StatusMessages />
      </div>
    )
  }
}

UploadDocumentPreview.propTypes = {
  t: PropTypes.func,
  showStatusMessage: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object,
}
